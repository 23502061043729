import React, { ComponentProps, forwardRef } from 'react';

import { useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import { Svg } from '~/assets/icons/styles';
import { Animate } from '~/assets/icons/types';

export const animateOpenIcon = (animate: Animate) => {
  animate(
    '.path:nth-child(1)',
    { scale: [1, 0.5, 1], x: [0, -2, 0], y: [0, 2, 0] },
    { duration: DURATION_IN_SEC, ease: 'easeInOut' },
  );
  animate(
    '.path:nth-child(2)',
    { pathLength: [1, 0.5, 1] },
    { duration: DURATION_IN_SEC, ease: 'easeInOut' },
  );
  animate('g', { x: [0, -3, 0], y: [0, 3, 0] }, { duration: DURATION_IN_SEC, ease: 'easeInOut' });
};

const DURATION_IN_SEC = 0.9;

const OpenIcon = forwardRef<SVGSVGElement, ComponentProps<'svg'>>(({ color, ...props }, ref) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.primary;

  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      {...props}
      ref={ref}
    >
      <path
        d="M11 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <motion.g>
        <motion.path
          d="M21 8.76001L21 3.00001L15.15 3.00001"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="path"
        />
        <motion.path
          d="M13.0643 11.0442L20.8696 3.16426"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="path"
        />
      </motion.g>
    </Svg>
  );
});

export default OpenIcon;
