import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import { Animate } from '~/assets/icons/types';

import { Svg } from './styles';

export const animateDownloadIcon = async (animate: Animate) => {
  animate(
    '.primaryArrow',
    { opacity: [0, 1], y: [-20, 0], scale: [0.2, 1] },
    { duration: 0.3, delay: 0.6 },
  );
  animate('.secondaryArrow', { opacity: [1, 0], y: [0, 20], scale: [1, 0] }, { duration: 0.3 });
};

const DownloadIcon = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<'svg'>>(
  ({ color = '', ...props }, ref) => {
    const theme = useTheme();
    const svgColor = color || theme.palette.common.white;

    return (
      <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
        svgColor={svgColor}
      >
        <path
          d="M2 18V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V18"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.g initial={{ opacity: 0, y: -20, scale: 0.2 }} className="primaryArrow">
          <path
            d="M12 2V14.5811"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.41895 10L12 14.5811L16.5811 10"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </motion.g>
        <motion.g initial={{ opacity: 1, y: 0, scale: 1 }} className="secondaryArrow">
          <path
            d="M12 2V14.5811"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.41895 10L12 14.5811L16.5811 10"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </motion.g>
      </Svg>
    );
  },
);
export default DownloadIcon;
