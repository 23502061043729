import React, { ComponentProps, forwardRef } from 'react';

import { useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import { Svg } from '~/assets/icons/styles';
import { Animate } from '~/assets/icons/types';

export const animateRatingIcon = (animate: Animate) => {
  animate('.path', { scale: [1, 1.3, 1] }, { duration: DURATION_IN_SEC, ease: 'easeInOut' });
  animate('g', { y: [0, -2, 0] }, { duration: DURATION_IN_SEC, ease: 'easeInOut' });
};

const DURATION_IN_SEC = 0.8;

const RatingIcon = forwardRef<SVGSVGElement, ComponentProps<'svg'>>(({ color, ...props }, ref) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.blue;

  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 1 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      ref={ref}
      {...props}
    >
      <motion.g>
        <path
          d="M1.87564 4.875C1.87564 3.63236 2.883 2.625 4.12564 2.625H19.875C21.1177 2.625 22.125 3.63236 22.125 4.875V16.7377C22.125 17.9803 21.1177 18.9877 19.875 18.9877H15.0844C14.7357 18.9877 14.4067 19.1494 14.1937 19.4255L12.891 21.1138C12.4407 21.6975 11.56 21.6975 11.1096 21.1138L9.80692 19.4255C9.59391 19.1494 9.26493 18.9877 8.91623 18.9877H4.12564C2.883 18.9877 1.87564 17.9803 1.87564 16.7377V4.875Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <motion.path
          d="M11.6302 7.10996C11.801 6.69932 12.3827 6.69932 12.5535 7.10996L13.2883 8.87664C13.3603 9.04975 13.5231 9.16804 13.71 9.18302L15.6173 9.33593C16.0606 9.37147 16.2404 9.92472 15.9026 10.2141L14.4494 11.4588C14.3071 11.5808 14.2449 11.7722 14.2884 11.9546L14.7323 13.8157C14.8355 14.2483 14.3649 14.5903 13.9853 14.3585L12.3525 13.3611C12.1924 13.2634 11.9912 13.2634 11.8312 13.3611L10.1983 14.3585C9.81876 14.5903 9.34814 14.2484 9.45133 13.8157L9.89529 11.9546C9.93879 11.7722 9.8766 11.5808 9.73421 11.4588L8.28107 10.2141C7.9433 9.92472 8.12307 9.37147 8.56639 9.33593L10.4737 9.18302C10.6606 9.16804 10.8234 9.04975 10.8954 8.87664L11.6302 7.10996Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinejoin="round"
          className="path"
        />
      </motion.g>
    </Svg>
  );
});

export default RatingIcon;
