import React, {
  cloneElement,
  ComponentProps,
  forwardRef,
  isValidElement,
  ReactElement,
} from 'react';

import { styled } from '@mui/material';
import Button from '@mui/material/Button';

import { AnimationName } from '~/assets/animationsMap';
import useIconAnimation from '~/libs/hooks/animations/useIconAnimation';

interface IconButtonProps extends ComponentProps<typeof Button> {
  className?: string;
  label?: string;
  icon: ReactElement;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  size: 'small' | 'medium' | 'large';
  animationName?: AnimationName;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { icon, label, className, onClick, disabled = false, onMouseEnter, animationName, ...rest },
    ref,
  ) => {
    const { scope, play } = useIconAnimation(animationName);

    return (
      <StyledButton
        ref={ref}
        disabled={disabled}
        className={className}
        onClick={onClick}
        {...rest}
        onMouseEnter={(e) => {
          play?.();
          onMouseEnter?.(e);
        }}
      >
        {isValidElement(icon) && animationName
          ? cloneElement(icon as ReactElement, { ref: scope })
          : icon}
        <ButtonLabel className="buttonLabel">{label}</ButtonLabel>
      </StyledButton>
    );
  },
);

export default IconButton;

const StyledButton = styled(Button)`
  min-height: 24px;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.light};
  border-radius: 0;
  transition: 0.1s ease all;
  display: flex;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }

  &:hover {
    background: transparent;

    & .buttonLabel {
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }

  &.Mui-disabled {
    opacity: 0.3;
  }
`;
const ButtonLabel = styled('span')`
  margin-left: 6px;
  color: ${({ theme }) => theme.palette.common.white};
`;
