import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import { Svg } from '~/assets/icons/styles';
import { Animate } from '~/assets/icons/types';

export const animateAddToList = (animate: Animate) => {
  animate('.path:nth-child(1)', { pathLength: [1, 0, 0, 1] }, { delay: 0.2, duration: 1.2 });
  animate('.path:nth-child(2)', { pathLength: [1, 0, 0, 1] }, { delay: 0.1, duration: 1.2 });
  animate('.path:nth-child(3)', { pathLength: [1, 0, 0, 1] }, { delay: 0, duration: 1.2 });
  animate('.group', { scale: [1, 1.5, 1.5, 1] }, { duration: 1.2 });
};

const AddToList = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<'svg'>>(
  ({ color, ...props }, ref) => {
    const theme = useTheme();
    const svgColor = color || theme.palette.common.primary;

    return (
      <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
        svgColor={svgColor}
      >
        <motion.path
          className="path"
          d="M4 5H16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.path
          className="path"
          d="M4 10H16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.path
          className="path"
          d="M4 15H9"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.g className="group">
          <path
            d="M14 18H22"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 14V22"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </motion.g>
      </Svg>
    );
  },
);

export default AddToList;
