import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import { Svg } from '~/assets/icons/styles';
import { Animate } from '~/assets/icons/types';

export const animateRemoveFromList = (animate: Animate) => {
  animate('path:nth-child(1)', { pathLength: [1, 0, 0, 1] }, { delay: 0.2, duration: 1.2 });
  animate('path:nth-child(2)', { pathLength: [1, 0, 0, 1] }, { delay: 0.1, duration: 1.2 });
  animate('path:nth-child(3)', { pathLength: [1, 0, 0, 1] }, { delay: 0, duration: 1.2 });
  animate(
    'path:nth-child(4)',
    { pathLength: [null, 1, 1, 0.3], y: [null, 0, 0, 3] },
    { duration: 1.2 },
  );
  animate('path:nth-child(5)', { pathLength: [null, 1, 1, 0.6] }, { duration: 1.2 });
};

const RemoveFromList = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<'svg'>>(
  ({ color = '', ...props }, ref) => {
    const theme = useTheme();
    const svgColor = color || theme.palette.common.primary;

    return (
      <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
        svgColor={svgColor}
      >
        <motion.path
          d="M4 5H16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.path
          d="M4 10H16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.path
          d="M4 15H9"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.path
          initial={{ pathLength: 0.3, y: 3 }}
          d="M12.0002 12.0002L20.8285 20.8285"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.path
          initial={{ pathLength: 0.6 }}
          d="M20.8284 12.0002L12.0001 20.8285"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    );
  },
);

export default RemoveFromList;
