import { PaletteMode, useMediaQuery, useTheme } from '@mui/material';
import { common } from '@mui/material/colors';
import { alpha, Breakpoint, createTheme, css, Theme } from '@mui/material/styles';

import { generateImageKitUrl } from '~/modules/imageKit';
import { TenantsRefs } from '~/utils/tenantsConfig';

import { MAIN_FONT_LINK } from './routes';

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    defaultFontFamily: string;
    headerFontFamily: string;
    specialFontFamily: string;
    fontLink: string;
  }

  interface Typography {
    defaultFontFamily: string;
    headerFontFamily: string;
    specialFontFamily: string;
    fontLink: string;
  }
}

export const BREAKPOINTS_VALUES = {
  xs: 0,
  sm: 500,
  md: 800,
  lg: 1100,
  xl: 1200,
  xxl: 1536,
  ml: 2200,
};

export const RESTRICTED_CONTAINER_WIDTH = 1600;

export const fuDarkPalette = {
  blackPure: '#000000',
  blackSecondary: '#23202E',
  white: '#ffffff',
  gray: '#C9C4D4',
  blue: '#6869ED',
  blueDark: '#1b1925',
  blueLight: '#20A4F3',
  violet: '#8686f1',
  violetSecondary: '#a2a4ee',
  violetSecondaryMid: '#b8b9fa',
  violetSecondaryLight: '#DCDCFF',
  violetAnalyticsCard: '#2D293B',
  primary: '#7EFCF0',
  pink: '#E5BFEE',
  green: '#86E380',
  failedError: '#F06965',
  yellow: '#E6FB7A',
  coral: '#F45B69',
  linkedInBlue: '#0077B7',
  transparent: 'transparent',
  successOptional: '#86E380',
};

const skillsDarkPalette = {
  blackPure: '#000000',
  blackSecondary: '#71808B',
  white: '#ffffff',
  gray: '#CEDEE7',
  blue: '#CC0000',
  blueDark: '#222222',
  blueLight: '#3366FF',
  violet: '#9BC3D3',
  violetSecondary: '#990000',
  violetSecondaryMid: '#990000',
  violetSecondaryLight: '#990000',
  violetAnalyticsCard: '#6F7779',
  primary: '#EC0000',
  pink: '#23779A',
  green: '#63BA68',
  failedError: '#990000',
  yellow: '#FFCC33',
  coral: '#9E3667',
  linkedInBlue: '#0077B7',
  transparent: 'transparent',
  successOptional: '#63BA68',
  bgOverlay: '#909090',
  softDisabled: '#F0F0F0',
  hover: '#2D2D2D',
  error: '#F67F7F',
  santRed: '#FAB2B2',
  lightGray: '#2D2D2D',
};

const skillsLightPalette = {
  blackPure: '#000000',
  blackSecondary: '#F5F9FB',
  white: '#000000',
  gray: '#6F7779',
  blue: '#9BC3D3',
  blueDark: '#FFFFFF',
  blueLight: '#3366FF',
  violet: '#CC0000',
  violetSecondary: '#990000',
  violetSecondaryMid: '#990000',
  violetSecondaryLight: '#990000',
  violetAnalyticsCard: '#CEDEE7',
  primary: '#EC0000',
  pink: '#23779A',
  green: '#63BA68',
  failedError: '#990000',
  yellow: '#FFCC33',
  coral: '#9E3667',
  linkedInBlue: '#0077B7',
  transparent: 'transparent',
  successOptional: '#008437',
  bgOverlay: '#909090',
  bgStrong: '#8F8F8F',
  blueSky: '#DEEDF2',
  hover: '#F6F6F6',
  santRed: '#FAB2B2',
  error: '#F67F7F',
  skillsDarkPalette,
};

const userAvatar = {
  coral: { background: fuDarkPalette.coral, color: fuDarkPalette.blueDark },
  blue: { background: fuDarkPalette.violet, color: fuDarkPalette.blueDark },
  blueLight: { background: fuDarkPalette.blueLight, color: fuDarkPalette.blueDark },
  yellow: { background: fuDarkPalette.yellow, color: fuDarkPalette.blueDark },
  pink: { background: fuDarkPalette.pink, color: fuDarkPalette.blueDark },
};

const fuColorsByAreas = {
  subcategories: fuDarkPalette.violetAnalyticsCard,
  divider: {
    main: fuDarkPalette.blue,
    discover: fuDarkPalette.violet,
    analyticsCard: fuDarkPalette.blue,
  },
  accent: fuDarkPalette.blue,
  accentFaded: fuDarkPalette.violet,
  textField: {
    hoverBorder: fuDarkPalette.blue,
    activeLabel: fuDarkPalette.blue,
  },
  accentShadow: fuDarkPalette.blackPure,
  accentMain: fuDarkPalette.blue,
  accentLight: fuDarkPalette.violet,
  answerItem: fuDarkPalette.blackSecondary,
  answerItemBorder: fuDarkPalette.blue,
  secondaryShadow: fuDarkPalette.blackPure,
  tooltip: {
    background: fuDarkPalette.violetSecondary,
    color: fuDarkPalette.white,
    link: fuDarkPalette.blue,
    bg: fuDarkPalette.blue,
    linkColor: fuDarkPalette.violetSecondary,
  },
  star: fuDarkPalette.white,
  highlightBg: fuDarkPalette.blue,
  status: {
    error: fuDarkPalette.failedError,
    brightError: fuDarkPalette.failedError,
  },
  blockBackground: {
    main: fuDarkPalette.blueDark,
    lightText: alpha(fuDarkPalette.gray, 0.3),
    contrast: fuDarkPalette.blackSecondary,
    light: fuDarkPalette.blackSecondary,
    lightContrast: fuDarkPalette.violetAnalyticsCard,
    lightBorder: fuDarkPalette.blackSecondary,
    accentText: fuDarkPalette.violetAnalyticsCard,
  },
  text: {
    white: fuDarkPalette.white,
    gray: fuDarkPalette.gray,
    violetSecondaryToWhite: fuDarkPalette.violetSecondary,
    fadedSubtitle: fuDarkPalette.gray,
    subtitle: fuDarkPalette.gray,
    secondaryAccent: fuDarkPalette.gray,
    secondaryToPrimary: fuDarkPalette.gray,
    secondaryAdditional: fuDarkPalette.gray,
    secondaryToWhite: fuDarkPalette.gray,
    meta: fuDarkPalette.violet,
    metaSecond: fuDarkPalette.blue,
    metaDark: alpha(fuDarkPalette.white, 0.3),
    cardLabel: alpha(fuDarkPalette.white, 0.3),
    title: fuDarkPalette.white,
    accordionTitle: fuDarkPalette.white,
    duration: fuDarkPalette.violet,
    label: fuDarkPalette.violet,
    accordionItemSubTitle: fuDarkPalette.gray,
  },
  success: {
    main: fuDarkPalette.green,
  },
  card: {
    accent: {
      bd: fuDarkPalette.blue,
    },
    dark: {
      bg: fuDarkPalette.blackSecondary,
    },
    overlay: {
      video: fuDarkPalette.blueDark,
      main: fuDarkPalette.blackSecondary,
      contrast: fuDarkPalette.blueDark,
    },
    secondary: fuDarkPalette.violet,
    chat: fuDarkPalette.blackSecondary,
    hoverAction: fuDarkPalette.violetAnalyticsCard,
    divider: fuDarkPalette.violetAnalyticsCard,
    accentDivider: fuDarkPalette.violet,
    lightAccent: fuDarkPalette.violetAnalyticsCard,
    darkAccent: fuDarkPalette.violetAnalyticsCard,
    lighterAccent: fuDarkPalette.violetAnalyticsCard,
    shadowValue: `0px 3px 5px ${alpha(fuDarkPalette.blackPure, 0.3)}`,
    background: {
      light: fuDarkPalette.violetAnalyticsCard,
      lightInverted: fuDarkPalette.violetAnalyticsCard,
      dark: fuDarkPalette.blackSecondary,
    },
    progressBackground: alpha(fuDarkPalette.white, 0.07),
    progressGradient: `linear-gradient(0deg, ${fuDarkPalette.blackSecondary} 0%, ${alpha(
      fuDarkPalette.blackSecondary,
      0.9,
    )} 28.85%, ${alpha(fuDarkPalette.blackSecondary, 0.0)} 100%)`,
    smallCardGradient: `linear-gradient(0deg, ${fuDarkPalette.blackSecondary} 0%, ${alpha(
      fuDarkPalette.blackSecondary,
      0.9,
    )} 17.24%, ${alpha(fuDarkPalette.violetAnalyticsCard, 0)} 100%)`,
    hoverGradient: `linear-gradient(
      0deg,
      ${fuDarkPalette.violetAnalyticsCard} 0%,
      ${alpha(fuDarkPalette.violetAnalyticsCard, 0.9)} 10%,
      ${alpha(fuDarkPalette.violetAnalyticsCard, 0)} 50%
    )
    0% 0% no-repeat`,
    smallCardBg: fuDarkPalette.blackSecondary,
    accordionBackground: fuDarkPalette.violetAnalyticsCard,
    certificateShadowValue: '',
    border: fuDarkPalette.gray,
    pathwayBorderMain: alpha(fuDarkPalette.gray, 0.1),
    pathwayBorderLight: alpha(fuDarkPalette.gray, 0.1),
    hover: fuDarkPalette.violetAnalyticsCard,
  },
  button: {
    collapseSecondary: fuDarkPalette.gray,
    background: fuDarkPalette.transparent,
    color: fuDarkPalette.blueDark,
    root: {
      bg: fuDarkPalette.blackSecondary,
      hover: alpha(fuDarkPalette.gray, 0.15),
      color: fuDarkPalette.gray,
      bgLight: fuDarkPalette.violetAnalyticsCard,
    },
    secondary: {
      contained: {
        bg: alpha(fuDarkPalette.white, 0.2),
        hover: {
          bg: alpha(fuDarkPalette.white, 0.08),
        },
        border: fuDarkPalette.gray,
        color: fuDarkPalette.white,
      },
      outlined: {
        bg: fuDarkPalette.transparent,
        border: fuDarkPalette.gray,
        hover: { bg: fuDarkPalette.gray, color: fuDarkPalette.blueDark },
      },
    },
  },
  panel: {
    secondary: fuDarkPalette.violetAnalyticsCard,
    background: fuDarkPalette.blackSecondary,
  },
  category: {
    primaryColor: fuDarkPalette.blueDark,
    color: fuDarkPalette.blueDark,
    secondary: fuDarkPalette.violet,
    pathway: fuDarkPalette.pink,
    cpd: fuDarkPalette.violetAnalyticsCard,
  },
  header: {
    shadowValue: `0px 3px 6px ${alpha(fuDarkPalette.blackPure, 0.4)}`,
    input: { bg: fuDarkPalette.violetAnalyticsCard, border: fuDarkPalette.transparent },
    switcher: fuDarkPalette.primary,
    notificationAccent: fuDarkPalette.coral,
    text: fuDarkPalette.white,
    background: fuDarkPalette.blueDark,
    staticBackground: fuDarkPalette.transparent,
    accentBackground: fuDarkPalette.white,
    accentText: alpha(fuDarkPalette.white, 0.33),
    color: fuDarkPalette.white,
    overlay: fuDarkPalette.blueDark,
    switcherBg: fuDarkPalette.violetAnalyticsCard,
    userAvatar,
  },
  icon: {
    active: fuDarkPalette.blue,
    gray: fuDarkPalette.gray,
    accordionIcon: fuDarkPalette.violet,
    autocompleteSearch: fuDarkPalette.white,
    primary: fuDarkPalette.primary,
  },
  footer: {
    bg: fuDarkPalette.blueDark,
    title: fuDarkPalette.white,
    hover: fuDarkPalette.white,
  },
  tabs: {
    underline: alpha(fuDarkPalette.violet, 0.3),
    indicator: fuDarkPalette.violet,
  },
  quiz: {
    bg: fuDarkPalette.violetAnalyticsCard,
  },
  hint: {
    color: fuDarkPalette.violetSecondary,
  },
  swiper: {
    buttonBg: fuDarkPalette.primary,
    buttonBorder: fuDarkPalette.white,
  },
  input: {
    searchColor: fuDarkPalette.gray,
  },
  scrollbar: {
    thumb: alpha(fuDarkPalette.blue, 0.33),
    track: fuDarkPalette.transparent,
  },
  progressBar: {
    color: fuDarkPalette.violet,
    circular: {
      trail: fuDarkPalette.gray,
      path: fuDarkPalette.blue,
    },
    line: {
      color: fuDarkPalette.blue,
    },
  },
  confetti: [
    fuDarkPalette.primary,
    fuDarkPalette.blue,
    alpha(fuDarkPalette.blue, 0.33),
    fuDarkPalette.gray,
    fuDarkPalette.pink,
    fuDarkPalette.blueLight,
  ],
  primaryAlternative: fuDarkPalette.primary,
  chips: {
    bg: {
      selected: fuDarkPalette.violetAnalyticsCard,
      regular: fuDarkPalette.blueDark,
    },
  },
  counter: fuDarkPalette.violet,
};

const skillsDarkColorsByAreas = {
  subcategories: skillsDarkPalette.blackPure,
  divider: {
    main: skillsDarkPalette.violetAnalyticsCard,
    discover: skillsDarkPalette.violetSecondary,
    analyticsCard: skillsDarkPalette.blackPure,
  },
  accent: skillsDarkPalette.primary,
  accentFaded: skillsDarkPalette.primary,
  textField: {
    hoverBorder: skillsDarkPalette.violetAnalyticsCard,
    activeLabel: skillsDarkPalette.white,
  },
  accentShadow: skillsDarkPalette.blackPure,
  accentMain: skillsDarkPalette.blue,
  accentLight: skillsDarkPalette.blue,
  answerItem: skillsDarkPalette.blueDark,
  answerItemBorder: skillsDarkPalette.blackPure,
  secondaryShadow: skillsDarkPalette.blackPure,
  tooltip: {
    background: skillsDarkPalette.primary,
    color: skillsDarkPalette.white,
    link: skillsDarkPalette.white,
    bg: skillsDarkPalette.violetSecondary,
    linkColor: skillsDarkPalette.santRed,
  },
  star: skillsDarkPalette.primary,
  highlightBg: skillsDarkPalette.violetSecondary,
  status: {
    error: skillsDarkPalette.error,
    brightError: skillsDarkPalette.error,
  },
  blockBackground: {
    main: skillsDarkPalette.blueDark,
    lightText: skillsDarkPalette.white,
    contrast: skillsDarkPalette.blue,
    light: skillsDarkPalette.blackPure,
    lightContrast: skillsDarkPalette.lightGray,
    lightBorder: skillsDarkPalette.blackPure,
    accentText: skillsDarkPalette.primary,
  },
  text: {
    white: skillsDarkPalette.white,
    gray: skillsDarkPalette.gray,
    violetSecondaryToWhite: skillsDarkPalette.white,
    fadedSubtitle: skillsDarkPalette.white,
    subtitle: skillsDarkPalette.primary,
    secondaryAccent: skillsDarkPalette.white,
    secondaryToPrimary: skillsDarkPalette.white,
    secondaryAdditional: skillsDarkPalette.white,
    secondaryToWhite: skillsDarkPalette.white,
    meta: skillsDarkPalette.bgOverlay,
    metaSecond: skillsLightPalette.blackSecondary,
    metaDark: skillsDarkPalette.bgOverlay,
    cardLabel: skillsDarkPalette.white,
    title: skillsDarkPalette.primary,
    accordionTitle: skillsDarkPalette.blackPure,
    duration: skillsDarkPalette.bgOverlay,
    label: skillsDarkPalette.white,
    accordionItemSubTitle: skillsDarkPalette.bgOverlay,
  },
  success: {
    main: skillsLightPalette.primary,
  },
  card: {
    accent: {
      bd: skillsDarkPalette.blue,
    },
    dark: {
      bg: skillsDarkPalette.blueDark,
    },
    overlay: {
      video: skillsDarkPalette.blueDark,
      main: skillsDarkPalette.blackPure,
      contrast: skillsDarkPalette.blueDark,
    },
    secondary: skillsDarkPalette.primary,
    chat: skillsDarkPalette.primary,
    hoverAction: skillsDarkPalette.blue,
    divider: skillsDarkPalette.violetAnalyticsCard,
    accentDivider: skillsDarkPalette.violetAnalyticsCard,
    lightAccent: skillsDarkPalette.blueDark,
    darkAccent: skillsDarkPalette.blueDark,
    lighterAccent: skillsDarkPalette.blueDark,
    shadowValue: `0px 3px 5px ${alpha(skillsLightPalette.blackPure, 0.3)}`,
    background: {
      dark: skillsDarkPalette.blackPure,
      light: skillsDarkPalette.blackPure,
      lightInverted: skillsDarkPalette.blackPure,
    },
    progressBackground: skillsDarkPalette.failedError,
    progressGradient: `linear-gradient(0deg, ${alpha(skillsDarkPalette.blackPure, 0.9)} 0%, ${alpha(
      fuDarkPalette.blackPure,
      0.0,
    )} 100%)`,
    smallCardGradient: `linear-gradient(0deg, ${skillsDarkPalette.blackPure} 10.45%, ${alpha(
      skillsDarkPalette.blackPure,
      0,
    )} 100%)`,
    hoverGradient: `linear-gradient(
      0deg,
      ${skillsDarkPalette.hover} 0%,
      ${alpha(skillsDarkPalette.hover, 0.9)} 10%,
      ${alpha(skillsDarkPalette.hover, 0)} 50%
    )
    0% 0% no-repeat`,
    smallCardBg: skillsDarkPalette.blackPure,
    accordionBackground: skillsDarkPalette.softDisabled,
    certificateShadowValue: '',
    border: skillsDarkPalette.gray,
    pathwayBorderMain: skillsDarkPalette.blueDark,
    pathwayBorderLight: skillsDarkPalette.hover,
    hover: skillsDarkPalette.hover,
  },
  button: {
    collapseSecondary: skillsDarkPalette.primary,
    background: skillsDarkPalette.primary,
    color: skillsDarkPalette.white,
    root: {
      bg: skillsDarkPalette.blueDark,
      hover: alpha(skillsDarkPalette.white, 0.1),
      color: skillsDarkPalette.white,
      bgLight: skillsDarkPalette.blueDark,
    },
    secondary: {
      contained: {
        bg: skillsDarkPalette.blueDark,
        hover: {
          bg: alpha(skillsDarkPalette.blueDark, 0.8),
        },
        border: skillsDarkPalette.blue,
        color: skillsDarkPalette.white,
      },
      outlined: {
        bg: skillsDarkPalette.blueDark,
        border: skillsDarkPalette.blue,
        hover: { bg: skillsDarkPalette.blue, color: skillsDarkPalette.white },
      },
    },
  },
  panel: {
    secondary: skillsDarkPalette.primary,
    background: skillsDarkPalette.blackPure,
  },
  category: {
    primaryColor: skillsDarkPalette.white,
    color: skillsDarkPalette.white,
    secondary: skillsDarkPalette.violetSecondary,
    pathway: skillsDarkPalette.coral,
    cpd: skillsDarkPalette.blackPure,
  },
  header: {
    shadowValue: `0px 3px 6px ${alpha(skillsDarkPalette.blackPure, 0.4)}`,
    input: {
      bg: alpha(skillsDarkPalette.blackPure, 0.35),
      border: skillsDarkPalette.transparent,
    },
    switcher: skillsDarkPalette.blackPure,
    notificationAccent: skillsDarkPalette.gray,
    text: skillsDarkPalette.white,
    background: skillsDarkPalette.primary,
    staticBackground: skillsDarkPalette.primary,
    accentBackground: skillsDarkPalette.violetSecondary,
    accentText: skillsDarkPalette.white,
    color: skillsDarkPalette.white,
    overlay: skillsDarkPalette.blackPure,
    switcherBg: skillsDarkPalette.violetSecondary,
    userAvatar,
  },
  icon: {
    active: skillsDarkPalette.primary,
    gray: skillsDarkPalette.bgOverlay,
    accordionIcon: skillsDarkPalette.primary,
    autocompleteSearch: skillsDarkPalette.bgOverlay,
    primary: skillsDarkPalette.white,
  },
  footer: {
    bg: skillsDarkPalette.blackPure,
    title: skillsDarkPalette.blue,
    hover: skillsDarkPalette.blue,
  },
  tabs: {
    underline: skillsDarkPalette.blueDark,
    indicator: skillsDarkPalette.primary,
  },
  quiz: {
    bg: skillsDarkPalette.blueDark,
  },
  hint: {
    color: skillsDarkPalette.white,
  },
  swiper: {
    buttonBg: skillsDarkPalette.primary,
    buttonBorder: skillsDarkPalette.white,
  },
  input: {
    searchColor: skillsDarkPalette.softDisabled,
  },
  scrollbar: {
    thumb: skillsDarkPalette.bgOverlay,
    track: skillsDarkPalette.blueDark,
  },
  progressBar: {
    color: skillsDarkPalette.primary,
    circular: {
      trail: skillsDarkPalette.violetSecondary,
      path: skillsDarkPalette.primary,
    },
    line: {
      color: skillsDarkPalette.primary,
    },
  },
  confetti: ['#FEE5E5', '#F67F7F', '#EC0000', '#CC0000', '#23779A'],
  primaryAlternative: skillsDarkPalette.white,
  chips: {
    bg: {
      selected: skillsDarkPalette.violetSecondary,
      regular: skillsDarkPalette.blueDark,
    },
  },
  counter: skillsDarkPalette.primary,
};

const skillsLightColorsByAreas = {
  subcategories: skillsLightPalette.pink,
  divider: {
    main: skillsLightPalette.blue,
    discover: skillsLightPalette.blue,
    analyticsCard: skillsLightPalette.violetAnalyticsCard,
  },
  accent: skillsLightPalette.primary,
  accentFaded: skillsLightPalette.primary,
  textField: {
    hoverBorder: skillsLightPalette.pink,
    activeLabel: skillsLightPalette.pink,
  },
  accentShadow: skillsLightPalette.primary,
  accentMain: skillsLightPalette.violet,
  accentLight: skillsLightPalette.violet,
  answerItem: skillsLightPalette.blackSecondary,
  answerItemBorder: skillsLightPalette.blue,
  secondaryShadow: skillsLightPalette.blue,
  tooltip: {
    background: skillsLightPalette.primary,
    color: skillsLightPalette.blackSecondary,
    link: skillsLightPalette.blueDark,
    bg: skillsLightPalette.violetSecondary,
    linkColor: skillsLightPalette.santRed,
  },
  star: skillsLightPalette.primary,
  highlightBg: skillsLightPalette.violetAnalyticsCard,
  status: {
    error: skillsLightPalette.violet,
    brightError: skillsLightPalette.error,
  },
  blockBackground: {
    main: skillsLightPalette.blackSecondary,
    lightText: skillsLightPalette.blackPure,
    contrast: skillsLightPalette.blackSecondary,
    light: skillsLightPalette.blueDark,
    lightContrast: skillsLightPalette.blueSky,
    lightBorder: skillsLightPalette.violetAnalyticsCard,
    accentText: skillsLightPalette.blue,
  },
  text: {
    white: skillsLightPalette.blueDark,
    gray: skillsLightPalette.gray,
    violetSecondaryToWhite: skillsLightPalette.blueDark,
    fadedSubtitle: skillsLightPalette.gray,
    subtitle: skillsLightPalette.pink,
    secondaryAccent: skillsLightPalette.blackPure,
    secondaryToPrimary: skillsLightPalette.blackPure,
    secondaryAdditional: skillsLightPalette.pink,
    secondaryToWhite: skillsLightPalette.blueDark,
    meta: skillsLightPalette.pink,
    metaSecond: skillsLightPalette.blackSecondary,
    metaDark: skillsLightPalette.bgStrong,
    cardLabel: skillsLightPalette.blueDark,
    title: skillsLightPalette.blackPure,
    accordionTitle: skillsLightPalette.blueDark,
    duration: skillsLightPalette.gray,
    label: skillsLightPalette.white,
    accordionItemSubTitle: skillsDarkPalette.pink,
  },
  success: {
    main: skillsDarkPalette.primary,
  },
  card: {
    accent: {
      bd: skillsDarkPalette.pink,
    },
    dark: {
      bg: skillsDarkPalette.blueDark,
    },
    overlay: {
      video: skillsLightPalette.skillsDarkPalette.blueDark,
      main: skillsLightPalette.blackSecondary,
      contrast: skillsLightPalette.blackPure,
    },
    secondary: skillsLightPalette.pink,
    chat: skillsDarkPalette.primary,
    hoverAction: skillsLightPalette.violet,
    divider: skillsLightPalette.blueSky,
    accentDivider: skillsLightPalette.blue,
    lightAccent: skillsLightPalette.violetAnalyticsCard,
    darkAccent: skillsLightPalette.blue,
    lighterAccent: skillsLightPalette.blackSecondary,
    shadowValue: `0px 3px 5px ${alpha(skillsLightPalette.gray, 0.3)}`,
    background: {
      dark: skillsLightPalette.blackSecondary,
      light: skillsLightPalette.blueDark,
      lightInverted: skillsLightPalette.blackSecondary,
    },
    progressBackground: skillsDarkPalette.failedError,
    progressGradient: `linear-gradient(0deg, ${alpha(
      skillsLightPalette.blackPure,
      0.9,
    )} 0%, ${alpha(skillsLightPalette.blackPure, 0.0)} 100%)`,
    smallCardGradient: `linear-gradient(0deg, ${skillsLightPalette.blackPure} 10.45%, ${alpha(
      skillsLightPalette.blackPure,
      0,
    )} 100%)`,
    hoverGradient: `linear-gradient(
      0deg,
      ${skillsLightPalette.hover} 0%,
      ${alpha(skillsLightPalette.hover, 0.9)} 10%,
      ${alpha(skillsLightPalette.hover, 0)} 50%
    )
    0% 0% no-repeat`,
    smallCardBg: skillsLightPalette.blackPure,
    accordionBackground: skillsLightPalette.pink,
    certificateShadowValue: `0px 3px 40px 0px ${alpha(skillsDarkPalette.blackPure, 0.08)}`,
    border: skillsLightPalette.pink,
    pathwayBorderMain: skillsLightPalette.blueSky,
    pathwayBorderLight: skillsLightPalette.blueSky,
    hover: skillsLightPalette.hover,
  },
  button: {
    collapseSecondary: skillsLightPalette.primary,
    background: skillsLightPalette.primary,
    color: skillsLightPalette.blueDark,
    root: {
      bg: skillsLightPalette.blueSky,
      hover: alpha(skillsLightPalette.blackSecondary, 0.15),
      color: skillsLightPalette.blue,
      bgLight: skillsLightPalette.blueSky,
    },
    secondary: {
      contained: {
        bg: skillsLightPalette.blueDark,
        hover: {
          bg: alpha(skillsLightPalette.blueDark, 0.8),
        },
        border: skillsLightPalette.violet,
        color: skillsLightPalette.blackPure,
      },
      outlined: {
        bg: skillsLightPalette.blueDark,
        border: skillsLightPalette.violet,
        hover: { bg: skillsLightPalette.violet, color: skillsLightPalette.blueDark },
      },
    },
  },
  panel: {
    secondary: skillsLightPalette.primary,
    background: skillsLightPalette.transparent,
  },
  category: {
    primaryColor: skillsLightPalette.blueDark,
    color: skillsLightPalette.blueDark,
    secondary: skillsLightPalette.violetSecondary,
    pathway: skillsLightPalette.coral,
    cpd: skillsLightPalette.blueSky,
  },
  header: {
    shadowValue: `none`,
    input: {
      bg: skillsLightPalette.blackSecondary,
      border: skillsLightPalette.blue,
    },
    switcher: skillsLightPalette.pink,
    notificationAccent: skillsLightPalette.primary,
    text: skillsLightPalette.gray,
    background: skillsLightPalette.blueDark,
    staticBackground: skillsLightPalette.blueDark,
    accentBackground: skillsLightPalette.blue,
    accentText: skillsLightPalette.gray,
    color: skillsLightPalette.primary,
    overlay: skillsLightPalette.blueDark,
    switcherBg: skillsLightPalette.blueSky,
    userAvatar,
  },
  icon: {
    active: skillsLightPalette.pink,
    gray: skillsLightPalette.bgOverlay,
    accordionIcon: skillsLightPalette.blueDark,
    autocompleteSearch: skillsLightPalette.bgOverlay,
    primary: skillsLightPalette.violet,
  },
  footer: {
    bg: skillsLightPalette.blackSecondary,
    title: skillsLightPalette.pink,
    hover: skillsLightPalette.pink,
  },
  tabs: {
    underline: alpha(skillsLightPalette.violet, 0.3),
    indicator: skillsLightPalette.violet,
  },
  quiz: {
    bg: skillsLightPalette.blueSky,
  },
  hint: {
    color: skillsLightPalette.blackPure,
  },
  swiper: {
    buttonBg: skillsLightPalette.pink,
    buttonBorder: skillsLightPalette.pink,
  },
  input: {
    searchColor: skillsLightPalette.blackPure,
  },
  scrollbar: {
    thumb: skillsLightPalette.violetAnalyticsCard,
    track: skillsLightPalette.blackSecondary,
  },
  progressBar: {
    color: skillsLightPalette.primary,
    circular: {
      trail: skillsLightPalette.error,
      path: skillsLightPalette.primary,
    },
    line: {
      color: skillsLightPalette.primary,
    },
  },
  confetti: ['#FEE5E5', '#F67F7F', '#EC0000', '#CC0000', '#23779A'],
  primaryAlternative: skillsLightPalette.blackSecondary,
  chips: {
    bg: {
      selected: skillsLightPalette.pink,
      regular: skillsLightPalette.blueSky,
    },
  },
  counter: skillsLightPalette.blueSky,
};

type ColorsPalette = typeof fuDarkPalette;

export const layers = {
  menu: 1050,
  pip: 90,
  stickyFooter: 100,
  toastNotification: 1500,
  modal: 1200,
  modalOverlay: 1150,
  dropdown: 1020,
};

export const HEADER_HEIGHT = 120;
export const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`;

export const INNER_BOTTOM_SPACE_DESK = 36;

export const TINY_MARGIN = 8;
export const SMALL_MARGIN = 18;
export const SUBMIDDLE_MARGIN = 24;
export const MIDDLE_MARGIN = 36;
export const BIG_MARGIN = 54;
export const LARGE_MARGIN = 72;

export const TINY_MARGIN_PX = `${TINY_MARGIN}px`;
export const SMALL_MARGIN_PX = `${SMALL_MARGIN}px`;
export const SUBMIDDLE_MARGIN_PX = `${SUBMIDDLE_MARGIN}px`;
export const MIDDLE_MARGIN_PX = `${MIDDLE_MARGIN}px`;
export const BIG_MARGIN_PX = `${BIG_MARGIN}px`;
export const LARGE_MARGIN_PX = `${LARGE_MARGIN}px`;

export const SMALL_RADIUS = 5;
export const MIDDLE_RADIUS = 8;
export const BIG_RADIUS = 16;
export const LARGE_RADIUS = 32;

export const SMALL_RADIUS_PX = `${SMALL_RADIUS}px`;
export const MIDDLE_RADIUS_PX = `${MIDDLE_RADIUS}px`;
export const BIG_RADIUS_PX = `${BIG_RADIUS}px`;
export const LARGE_RADIUS_PX = `${LARGE_RADIUS}px`;

const SKELETON_TEXT_HEIGHT_TINY = 18;
const SKELETON_TEXT_HEIGHT_SMALL = 24;
const SKELETON_TEXT_HEIGHT = 36;
const SKELETON_TEXT_HEIGHT_BIG = 54;
export const SKELETON_TEXT_HEIGHT_TINY_PX = `${SKELETON_TEXT_HEIGHT_TINY}px`;
export const SKELETON_TEXT_HEIGHT_SMALL_PX = `${SKELETON_TEXT_HEIGHT_SMALL}px`;
export const SKELETON_TEXT_HEIGHT_PX = `${SKELETON_TEXT_HEIGHT}px`;
export const SKELETON_TEXT_HEIGHT_BIG_PX = `${SKELETON_TEXT_HEIGHT_BIG}px`;

const typographyTokens = {
  fontFamily: '"sofia-pro", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  defaultFontFamily: '"sofia-pro", sans-serif',
  headerFontFamily: '"sofia-pro", sans-serif',
  specialFontFamily: '"sofia-pro", sans-serif',
  fontLink: MAIN_FONT_LINK,
};

const skillsTypographyTokens = {
  fontFamily: '"Santander Micro Text", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  defaultFontFamily: '"Santander Micro Text", sans-serif',
  headerFontFamily: '"Santander Headline", sans-serif',
  specialFontFamily: '"Embers", sans-serif',
  fontLink: generateImageKitUrl({
    path: '/fonts/skillsunlockedFonts.css',
    transformation: undefined,
  }),
};

const themeCommonTokens = {
  breakpoints: { values: BREAKPOINTS_VALUES },
  components: {
    MuiContainer: {
      styleOverrides: {},
    },
  },
};

const themeColorTokens = {
  common: {
    ...fuDarkPalette,
    ...fuColorsByAreas,
  },
  primary: {
    light: fuDarkPalette.primary,
    main: fuDarkPalette.primary,
    dark: fuDarkPalette.primary,
    contrastText: common.black,
  },
  secondary: {
    light: fuDarkPalette.blackSecondary,
    main: fuDarkPalette.blackSecondary,
    dark: fuDarkPalette.blueDark,
    contrastText: common.white,
  },
};

const skillsLightColorTokens = {
  common: {
    ...skillsLightPalette,
    ...skillsLightColorsByAreas,
  },
  primary: {
    light: skillsLightPalette.primary,
    main: skillsLightPalette.primary,
    dark: skillsLightPalette.primary,
    contrastText: common.black,
  },
  secondary: {
    light: skillsLightPalette.blackSecondary,
    main: skillsLightPalette.blackSecondary,
    dark: skillsLightPalette.blueDark,
    contrastText: common.white,
  },
};

const skillsDarkColorTokens = {
  common: {
    ...skillsDarkPalette,
    ...skillsDarkColorsByAreas,
  },
  primary: {
    light: skillsDarkPalette.primary,
    main: skillsDarkPalette.primary,
    dark: skillsDarkPalette.primary,
    contrastText: common.black,
  },
  secondary: {
    light: skillsDarkPalette.blackSecondary,
    main: skillsDarkPalette.blackSecondary,
    dark: skillsDarkPalette.blueDark,
    contrastText: common.white,
  },
};

const getDefaultDesignTokens = (mode: PaletteMode) => ({
  name: TenantsRefs.Fu,
  isSsku: false,
  palette: {
    mode,
    ...(mode === 'light' ? themeColorTokens : themeColorTokens),
  },
  typography: typographyTokens,
  ...themeCommonTokens,
});

const getSkillsDesignTokens = (mode: PaletteMode) => ({
  name: TenantsRefs.Ssku,
  isSsku: true,
  palette: {
    mode,
    ...(mode === 'light' ? skillsLightColorTokens : skillsDarkColorTokens),
  },
  typography: skillsTypographyTokens,
  ...themeCommonTokens,
});

export const getDefaultTransition = (type = 'all') => {
  return `${type} .3s ease`;
};

export type TenantTheme = Record<'dark' | 'light', Theme>;
export const fuTenantTheme: TenantTheme = {
  dark: createTheme(getDefaultDesignTokens('dark')),
  light: createTheme(getDefaultDesignTokens('light')),
};
export const skillsTheme: TenantTheme = {
  dark: createTheme(getSkillsDesignTokens('dark')),
  light: createTheme(getSkillsDesignTokens('light')),
};

export const tenantThemes: TenantTheme[] = [fuTenantTheme, skillsTheme];

const createComponentOverrides = (theme: Theme) => ({
  MuiContainer: {
    root: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    maxWidthLg: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 1200,
      },
    },
  },
  MuiAppBar: {
    root: ({ theme }) => css`
      color: ${theme.palette.common.header.text};
    `,
  },
  MuiAlert: {
    filledSuccess: {
      color: alpha(theme.palette.common.blackPure, 0.87),
      backgroundColor: theme.palette.common.primary,
    },
  },
  MuiTabs: {
    flexContainer: {
      borderBottom: `2px solid ${theme.palette.common.tabs.underline}`,
    },
    indicator: {
      backgroundColor: `${theme.palette.common.tabs.indicator}`,
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      marginRight: 15,
      fontSize: 20,
      fontWeight: 'bold',
      textTransform: 'none',

      [theme.breakpoints.up('sm')]: {
        minWidth: 0,
      },
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiList: {
    root: {
      background: theme.palette.common.violetAnalyticsCard,
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 8,
      borderColor: alpha(theme.palette.common.white, 0.33),
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.textField.hoverBorder,
        borderWidth: 1,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.textField.hoverBorder,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.failedError,
      },
    },
    input: {
      color: theme.palette.common.gray,
      fontSize: 18,
      lineHeight: '26px',
      '&:-webkit-autofill': {
        color: theme.palette.common.gray,
        boxShadow: `0 0 0 100px ${theme.palette.common.violetAnalyticsCard} inset`,
      },
    },
    notchedOutline: {
      '& legend': {
        fontSize: 14,
      },
    },
    adornedEnd: {
      paddingRight: 7,
    },
  },
  MuiInputLabel: {
    root: {
      color: alpha(theme.palette.common.white, 0.33),
      fontSize: 18,
      lineHeight: '26px',
      '&.Mui-focused': {
        color: theme.palette.common.textField.activeLabel,
      },
      '&.Mui-error': {
        color: theme.palette.common.failedError,
      },
    },
    outlined: {
      transform: 'translate(14px, 16px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -10px) scale(0.75)',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      '&.Mui-error': {
        color: theme.palette.common.failedError,
      },
    },
  },
  MuiAutocomplete: {
    listbox: {
      '& .MuiAutocomplete-option.Mui-focused': {
        backgroundColor: theme.palette.common.card.hover,
      },
    },
    tag: {
      borderRadius: 16,
    },
    clearIndicator: {
      display: 'none',
    },
    popupIndicator: {
      color: alpha(theme.palette.common.white, 0.33),
    },
    popupIndicatorOpen: {
      color: theme.palette.common.blue,
    },
    paper: {
      margin: 0,
      backgroundColor: theme.palette.common.blockBackground.light,
      backgroundImage: 'none',
    },
    option: {
      fontSize: 18,
      lineHeight: '26px',
      color: theme.palette.common.text.secondaryToPrimary,
      padding: '16px 8px',
      '&.Mui-focused': {
        backgroundColor: theme.palette.common.card.hover,
      },
    },
  },
  MuiCalendarPicker: {
    root: {
      backgroundColor: [theme.palette.secondary.light],
      '& > div:first-child': {
        padding: 0,
        '& > div:first-child': {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          pointerEvents: 'none',
          '& .MuiButtonBase-root': {
            display: 'none',
          },
        },
        '& > div:last-child': {
          width: '100%',
          justifyContent: 'space-between',
          '& .MuiIconButton-root': {
            background: theme.palette.common.violetAnalyticsCard,
            '&:hover': {
              background: alpha(theme.palette.common.white, 0.08),
            },
            '& .Mui-disabled': {
              background: 'transparent',
            },
          },
        },
      },
      '& .PrivatePickersSlideTransition-root ': {
        minHeight: 240,
      },
    },
  },
  MuiPickersBasePicker: {
    pickerView: {
      backgroundColor: [theme.palette.secondary.light],
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: theme.palette.common.violetAnalyticsCard,
    },
  },
  MuiPickersDay: {
    root: {
      fontSize: 14,
      backgroundColor: [theme.palette.secondary.light],
      '&:hover': {
        backgroundColor: theme.palette.common.violetAnalyticsCard,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.common.primary,
      },
    },
    today: {
      border: 'none !important',
      color: theme.palette.common.primary,
      '&:hover': {
        backgroundColor: theme.palette.common.violetAnalyticsCard,
      },
      '&:focus': {
        color: theme.palette.common.blueDark,
        backgroundColor: theme.palette.common.primary,
      },
    },
  },
  MuiAccordion: {
    root: {
      backgroundImage: 'none',
    },
  },
  MuiLink: {
    root: {
      textDecoration: 'none',
    },
  },
  MuiButton: {
    root: ({ theme }) => css`
      &:hover {
        background: ${alpha(theme.palette.common.button.root.color, 0.15)};
      }
    `,
  },
  MuiCircularProgress: {
    circle: css`
      stroke-linecap: round;
    `,
    root: ({ theme }) => css`
      color: ${theme.palette.common.accent};
    `,
  },
  MuiDialog: {
    container: css`
      width: 100%;
    `,
    paper: css`
      flex-direction: row;
      justify-content: center;
      width: 100%;
      max-width: unset;
      margin: 0;
      background: transparent;
      box-shadow: none;
    `,
  },
});

tenantThemes
  .flatMap((tenantTheme) => Object.values(tenantTheme))
  .forEach((theme) => {
    for (const tKey in createComponentOverrides(theme)) {
      if (theme.components) {
        theme.components[tKey] = {};
        theme.components[tKey].styleOverrides = createComponentOverrides(theme)[tKey];
      }
    }

    theme.typography.h1 = {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 'bold',
      marginBottom: 0,
      fontFamily: theme.typography.headerFontFamily,
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
        lineHeight: 1.2,
        marginBottom: 0,
      },
    };

    theme.typography.h2 = {
      fontSize: 24,
      lineHeight: '29px',
      fontWeight: 'bold',
      marginBottom: 22,
      fontFamily: theme.typography.headerFontFamily,
      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
        lineHeight: 1.2,
        marginBottom: 0,
      },
    };

    theme.typography.h3 = {
      fontSize: 18,
      lineHeight: '22px',
      fontWeight: 'bold',
      marginBottom: 8,
      fontFamily: theme.typography.headerFontFamily,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: 1.2,
        marginBottom: 0,
      },
    };

    theme.typography.h4 = {
      fontSize: 22,
      lineHeight: '22px',
      fontWeight: 'bold',
      marginBottom: 0,
      fontFamily: theme.typography.headerFontFamily,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '22px',
        marginBottom: 0,
      },
    };

    theme.typography.h5 = {
      fontFamily: theme.typography.headerFontFamily,
    };

    theme.typography.h6 = {
      fontFamily: theme.typography.headerFontFamily,
    };
  });

export function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}

export function useIsWidthBetween(start, end) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between(start, end));
}

export function useBreakpoint(defaultWidth: Breakpoint = 'sm') {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const breakpoint: Breakpoint =
    keys.reduce((output: null | Breakpoint, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || defaultWidth;

  return breakpoint;
}

type AdditionalCommonColors = typeof fuColorsByAreas;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
    ml: true;
  }

  interface CommonColors extends ColorsPalette, AdditionalCommonColors {}

  interface Theme {
    isSsku: boolean;
    name: TenantsRefs;
  }
}

// todo: remove "| string" after refactoring
export type CommonColor = Theme['palette']['common'] | string;

export default fuTenantTheme.dark;
