import React, { FC, ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import PathwayNewIcon from '~/assets/icons/PathwayNewIcon';
import RatingIcon from '~/assets/icons/RatingIcon';
import ReminderIcon from '~/assets/icons/ReminderIcon';
import ReminderNewIcon from '~/assets/icons/ReminderNewIcon';
import VideoFollowingIcon from '~/assets/icons/VideoFollowingIcon';
import VideoNewIcon from '~/assets/icons/VideoNewIcon';
import { MIDDLE_RADIUS_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX, TINY_MARGIN_PX } from '~/theme';
import { Maybe, NotificationType } from '~/types';
import assertNever from '~/utils/errors/assertNever';

export enum AnnouncementType {
  FollowedVideo = 'followedVideo',
  Video = 'video',
  Pathway = 'pathway',
  PathwayReview = 'pathwayReview',
  CourseReview = 'courseReview',
}

type CombinedNotification = NotificationType | AnnouncementType;

interface NotificationProps {
  title: string;
  subtitle: ReactNode;
  highlighted?: Maybe<boolean>;
  type?: Maybe<NotificationType | AnnouncementType>;
  className?: string;
  link?: string;
  onClick?: () => void;
  id?: Maybe<string>;
}

const Notification: FC<NotificationProps> = ({
  title,
  subtitle,
  highlighted,
  type,
  className,
  link,
  onClick,
  id,
}) => {
  const hasLink = !!link;
  const Icon = getIcon(type);
  return (
    <li className={className}>
      <Content
        data-testid="notification"
        as={hasLink ? Link : undefined}
        to={hasLink ? link : undefined}
        onClick={hasLink ? onClick : undefined}
        highlighted={highlighted}
      >
        <Icon id={id ? id : undefined} secondaryColor="transparent" />
        <div>
          <Title data-testid="title">{title}</Title>
          <Subtitle data-testid="subtitle">{subtitle}</Subtitle>
        </div>
      </Content>
    </li>
  );
};

export default Notification;

const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'highlighted' && prop !== 'as',
})<{
  highlighted?: Maybe<boolean>;
  to?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${MIDDLE_RADIUS_PX};
  padding: ${TINY_MARGIN_PX} ${TINY_MARGIN_PX} ${TINY_MARGIN_PX} ${SMALL_MARGIN_PX};
  background: ${({ highlighted, theme }) =>
    highlighted
      ? alpha(theme.palette.common.blue, 0.33)
      : theme.palette.common.violetAnalyticsCard};
  overflow: hidden;
  transition: 0.3s margin, 0.3s background-color;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${TINY_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
    background: ${({ theme }) => theme.palette.common.card.lightAccent};
  }

  &:hover {
    background: ${({ to, theme }) => to && alpha(theme.palette.common.gray, 0.1)};
    margin-left: ${({ to }) => to && `-${TINY_MARGIN_PX}`};
    margin-right: ${({ to }) => to && `-${TINY_MARGIN_PX}`};
  }

  &::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background: ${({ theme }) => theme.palette.common.blue};

    ${({ theme }) => theme.breakpoints.up('md')} {
      display: ${({ highlighted }) => highlighted && 'block'};
    }
  }
`;
const Title = styled(Typography)`
  display: block;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
`;
const Subtitle = styled('div')`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.common.text.fadedSubtitle};
`;
const createStyledIcon = <T extends {}>(tag: FC<T>) => styled(tag, {
  shouldForwardProp: (prop) => prop !== 'secondaryColor',
})`
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: ${SMALL_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.white};
`;
const StyledPathwayNewIcon = createStyledIcon(PathwayNewIcon);
const StyledReminderNewIcon = createStyledIcon(ReminderNewIcon);
const StyledReminderIcon = createStyledIcon(ReminderIcon);
const StyledVideoNewIcon = createStyledIcon(VideoNewIcon);
const StyledRatingIcon = createStyledIcon(RatingIcon);
const StyledVideoFollowingIcon = createStyledIcon(VideoFollowingIcon);

const getIcon = (type?: Maybe<CombinedNotification>) => {
  switch (type) {
    case undefined:
    case null:
    case NotificationType.Reminder:
      return StyledReminderIcon;
    case NotificationType.New:
      return StyledReminderNewIcon;
    case AnnouncementType.Pathway:
      return StyledPathwayNewIcon;
    case AnnouncementType.Video:
      return StyledVideoNewIcon;
    case AnnouncementType.FollowedVideo:
      return StyledVideoFollowingIcon;
    case AnnouncementType.PathwayReview:
    case AnnouncementType.CourseReview:
      return StyledRatingIcon;
    default:
      assertNever(type);
      return StyledReminderIcon;
  }
};
