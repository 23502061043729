import { useEffect, useRef } from 'react';

import { addMilliseconds, isAfter } from 'date-fns';
import { useLocation } from 'react-router-dom';

import useAuth from '~/hooks/useAuth';

const TIMEOUT =
  process.env.REACT_APP_BUILD_TYPE === 'stage' ||
  process.env.REACT_APP_BUILD_TYPE === 'pre-prod' ||
  process.env.REACT_APP_BUILD_TYPE === 'dev'
    ? 1000 * 60
    : 1000 * 60 * 60 * 4;
const KEY = 'lastTimeLocationChanged';

const useTimedOutSession = (newSessionHandler: Function) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const newSessionHandlerRef = useRef<Function>();
  newSessionHandlerRef.current = newSessionHandler;

  useEffect(() => {
    const lastTimeStr = localStorage.getItem(KEY);
    const currentDate = new Date();

    // new session
    if (!lastTimeStr) {
      localStorage.setItem(KEY, currentDate.toString());
      newSessionHandlerRef.current?.();
      return;
    }

    const lastTime = new Date(lastTimeStr);

    // times out: new session
    if (isAfter(currentDate, addMilliseconds(lastTime, TIMEOUT))) {
      localStorage.setItem(KEY, currentDate.toString());
      newSessionHandlerRef.current?.();
      return;
    }

    // same session
    localStorage.setItem(KEY, currentDate.toString());
  }, [location, newSessionHandlerRef, isAuthenticated]);
};

export default useTimedOutSession;
