import React, { ComponentProps, forwardRef } from 'react';

import { useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import { Svg } from '~/assets/icons/styles';
import { Animate } from '~/assets/icons/types';

export const animateLinkedInIcon = (animate: Animate) => {
  animate('g', { opacity: [0, 1, 0] }, { duration: DURATION_IN_SEC, ease: 'easeInOut' });
  animate('.path', { opacity: [1, 0, 1] }, { duration: DURATION_IN_SEC, ease: 'easeInOut' });
};

const DURATION_IN_SEC = 1.5;

const LinkedInIcon = forwardRef<SVGSVGElement, ComponentProps<'svg'>>(
  ({ color, ...props }, ref) => {
    const theme = useTheme();
    const svgColor = color || theme.palette.common.primary;

    return (
      <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        svgColor={svgColor}
        ref={ref}
        {...props}
      >
        <motion.g initial={{ opacity: 0 }}>
          <mask id="path-1-inside-1_46979_12716" fill="currentColor">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.2857 0H1.70893C0.766082 0 0 0.776786 0 1.73036V22.2696C0 23.2232 0.766066 24 1.70893 24H22.2857C23.2286 24 24 23.2232 24 22.2696V1.73036C24 0.776786 23.2286 0 22.2857 0Z"
            />
          </mask>
          <path
            d="M22.2857 -2H1.70893V2H22.2857V-2ZM1.70893 -2C-0.362728 -2 -2 -0.303388 -2 1.73036H2C2 1.85696 1.89489 2 1.70893 2V-2ZM-2 1.73036V22.2696H2V1.73036H-2ZM-2 22.2696C-2 24.3034 -0.362754 26 1.70893 26V22C1.89489 22 2 22.1431 2 22.2696H-2ZM1.70893 26H22.2857V22H1.70893V26ZM22.2857 26C24.3493 26 26 24.3116 26 22.2696H22C22 22.1349 22.1079 22 22.2857 22V26ZM26 22.2696V1.73036H22V22.2696H26ZM26 1.73036C26 -0.31157 24.3493 -2 22.2857 -2V2C22.1079 2 22 1.86514 22 1.73036H26Z"
            fill="currentColor"
            mask="url(#path-1-inside-1_46979_12716)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.25405 20.5712H3.6969V9.11768H7.2594V20.5712H7.25405Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.47656 7.55492C4.33549 7.55492 3.41406 6.62813 3.41406 5.49243C3.41406 4.35672 4.33549 3.42993 5.47656 3.42993C6.61227 3.42993 7.53907 4.35672 7.53907 5.49243C7.53907 6.6335 6.61764 7.55492 5.47656 7.55492Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5876 20.5715H17.0304V15C17.0304 13.6715 17.0036 11.9625 15.1822 11.9625C13.3286 11.9625 13.0447 13.409 13.0447 14.9036V20.5715H9.48755V9.1179H12.9V10.6822H12.9483C13.4251 9.7822 14.5876 8.83398 16.3179 8.83398C19.9179 8.83398 20.5876 11.2072 20.5876 14.2929L20.5876 20.5715Z"
            fill="currentColor"
          />
        </motion.g>
        <motion.path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2857 0H1.70893C0.766082 0 0 0.776786 0 1.73036V22.2696C0 23.2232 0.766066 24 1.70893 24H22.2857C23.2286 24 24 23.2232 24 22.2696V1.73036C24 0.776786 23.2286 0 22.2857 0ZM7.25402 20.5713H3.69687V9.11775H7.25938V20.5713H7.25402ZM5.47656 7.55496C4.33549 7.55496 3.41406 6.62817 3.41406 5.49246C3.41406 4.35676 4.33549 3.42997 5.47656 3.42997C6.61227 3.42997 7.53907 4.35676 7.53907 5.49246C7.53907 6.63354 6.61764 7.55496 5.47656 7.55496ZM20.5875 20.5715H17.0304V15C17.0304 13.6715 17.0036 11.9625 15.1822 11.9625C13.3286 11.9625 13.0446 13.409 13.0446 14.9036V20.5715H9.4875V9.1179H12.9V10.6822H12.9482C13.425 9.7822 14.5875 8.83398 16.3179 8.83398C19.9179 8.83398 20.5875 11.2072 20.5875 14.2929L20.5875 20.5715Z"
          fill="currentColor"
          className="path"
        />
      </Svg>
    );
  },
);

export default LinkedInIcon;
