import React, { ComponentProps, forwardRef } from 'react';

import { motion } from 'framer-motion';

import { Svg, useSvgColor } from '~/assets/icons/styles';
import { Animate, TempColorIconProps } from '~/assets/icons/types';

export const animateShareIcon = (animate: Animate) => {
  animate(
    '.circle:nth-child(2)',
    { scale: [1, 0, 1], x: [0, -9.1, 0], y: [0, 4.1, 0] },
    { duration: DURATION_IN_SEC },
  );
  animate('.path:nth-child(3)', { pathLength: [1, 0, 1] }, { duration: DURATION_IN_SEC });
  animate(
    '.path:nth-child(4)',
    { pathLength: [1, 0, 1] },
    { duration: DURATION_IN_SEC, delay: 0.3 },
  );
  animate(
    '.circle:nth-child(5)',
    { scale: [1, 0, 1], x: [0, -9.1, 0], y: [0, -4.1, 0] },
    { duration: DURATION_IN_SEC, delay: 0.3 },
  );
};

const DURATION_IN_SEC = 1.2;

const ShareIcon = forwardRef<SVGSVGElement, TempColorIconProps & ComponentProps<'svg'>>(
  ({ updatedColor, color, ...props }, ref) => {
    const svgColor = useSvgColor(color);

    return (
      <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        svgColor={updatedColor || svgColor}
        {...props}
      >
        <path
          d="M6.40005 14.4C7.72556 14.4 8.8001 13.3254 8.8001 11.9999C8.8001 10.6744 7.72556 9.59985 6.40005 9.59985C5.07454 9.59985 4 10.6744 4 11.9999C4 13.3254 5.07454 14.4 6.40005 14.4Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.path
          d="M17.5998 8.8001C18.9253 8.8001 19.9998 7.72556 19.9998 6.40005C19.9998 5.07454 18.9253 4 17.5998 4C16.2742 4 15.1997 5.07454 15.1997 6.40005C15.1997 7.72556 16.2742 8.8001 17.5998 8.8001Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="circle"
        />
        <motion.path
          initial={{
            rotate: 180,
          }}
          d="M15.4534 7.47302L8.54697 10.9262"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="path"
        />
        <motion.path
          d="M8.54697 13.0742L15.4534 16.5274"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="path"
        />
        <motion.path
          d="M17.5998 19.9998C18.9253 19.9998 19.9998 18.9253 19.9998 17.5998C19.9998 16.2742 18.9253 15.1997 17.5998 15.1997C16.2742 15.1997 15.1997 16.2742 15.1997 17.5998C15.1997 18.9253 16.2742 19.9998 17.5998 19.9998Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="circle"
        />
      </Svg>
    );
  },
);

export default ShareIcon;
