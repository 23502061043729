import { animateAddToList } from '~/assets/icons/AddToList';
import { animateDownloadIcon } from '~/assets/icons/DownloadIcon';
import { animateLinkedInIcon } from '~/assets/icons/LinkedInIcon';
import { animateOpenIcon } from '~/assets/icons/OpenIcon';
import { animateRatingIcon } from '~/assets/icons/RatingIcon';
import { animateRemoveFromList } from '~/assets/icons/RemoveFromList';
import { animateSearchIcon } from '~/assets/icons/SearchIcon';
import { animateShareIcon } from '~/assets/icons/ShareIcon';

const animationsMap = {
  RemoveFromList: animateRemoveFromList,
  AddToList: animateAddToList,
  DownloadIcon: animateDownloadIcon,
  OpenIcon: animateOpenIcon,
  RatingIcon: animateRatingIcon,
  LinkedInIcon: animateLinkedInIcon,
  ShareIcon: animateShareIcon,
  SearchIcon: animateSearchIcon,
};

export type AnimationName = keyof typeof animationsMap;

export default animationsMap;
