import { useCallback } from 'react';

import { useAnimate } from 'framer-motion';

import animationsMap, { AnimationName } from '~/assets/animationsMap';

const useIconAnimation = (animationName?: AnimationName) => {
  const [scope, animate] = useAnimate();
  const playIconAnimation = animationName && animationsMap[animationName];

  const play = useCallback(() => playIconAnimation?.(animate), [animate, playIconAnimation]);

  return {
    scope,
    play,
  };
};

export default useIconAnimation;
