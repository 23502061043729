import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { motion } from 'framer-motion';

import { Svg, useSvgColor } from '~/assets/icons/styles';
import { Animate } from '~/assets/icons/types';

export const animateSearchIcon = async (animate: Animate) => {
  animate(
    '.rotationGroup',
    {
      rotate: [0, -360],
      x: [0, -5, 0],
      y: [0, 3, 0],
    },
    {
      duration: 1.3,
      ease: 'easeInOut',
    },
  );
  animate(
    '.scaleGroup',
    { rotate: [0, 360], scale: [1, 0.6, 1] },
    { duration: 1.3, ease: 'easeInOut' },
  );
};

const SearchIcon = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<'svg'>>(
  ({ color, ...props }, ref) => {
    const svgColor = useSvgColor(color);

    return (
      <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        svgColor={svgColor}
        ref={ref}
        {...props}
      >
        <motion.g className="scaleGroup">
          <motion.g className="rotationGroup">
            <path
              d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 22.0001L17.3628 17.3621"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </motion.g>
        </motion.g>
      </Svg>
    );
  },
);

export default SearchIcon;
